.profilePicStyles {
  width: 100;
  height: 180px;
  background-color: #fafaff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  box-shadow: -0.1px -0.1px 1px 2px  #F7F7F7;
  position: relative;
}
.customerName {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
}
.panCard{
  min-height: 280px;
  width: 100%;
  background-color: #FFFFFF;  
  margin-top: 10px;
  border-radius: 8px;
  padding: 16px;
  /* box-shadow: -0.1px -0.1px 1px 2px rgba(106, 115, 129, 0.12); */
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}
.panCardUploadCard{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0px 8px 8px 8px;
  /* width: 50%; */
  width: 100%;
  /* box-shadow: 0px 2px 8px rgba(106, 115, 129, 0.12); */
  border-radius:8px ;
}
.uploadCard{
  /* background-color:#F7F7F7; ; */
  background-color: #f7f9fb;

  width: 100%;
  height: 86px;
  border-radius: 4px;
  /* box-shadow: -0.1px -0.1px 1px 2px rgba(106, 115, 129, 0.12); */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  /* background-image: url(../images/uploadImages.svg); */
}
.uploadIcon{
height: 20px;
width: 20px;
display: flex;
align-items: center;
justify-content: center;
}
.radioButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
}
.aadharStyles{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  /* box-shadow: 0px 2px 8px rgba(106, 115, 129, 0.12); */
  background-color: #f7f9fb;
  border-radius:4px ;
  margin: 10px;
}
/* .custom-control-inline{

} */

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px; 
  height: 20px;
  border: 2px solid #F48120; 
  border-radius: 50%; 
  margin-right: 8px;
  padding: 1px;
}

/* Style the checked radio button */
input[type="radio"]:checked {
  background-color: #F48120;
  border-color:#F48120;
} 
.form-check-input:focus {
  /* border-color: #86b7fe; */
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(245, 157, 23, 0.2);
}

.usersPancard {
  width: 100%;
  background-color: #FFFFFF;  
  border-radius: 8px;
  padding: 16px;
  box-shadow: -0.1px -0.1px 1px 2px rgba(106, 115, 129, 0.12);
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.usersPanUploadCard{
  min-height: 180px;
  background-color:#F7F7F7; ;
  width: '100%';
  height: 86px;
  border-radius: 4px;
  box-shadow: -0.1px -0.1px 1px 2px rgba(106, 115, 129, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  /* background-image: url(../images/uploadImages.svg); */
}

.InputScreen{
  /* background-image: url("../images/BgInvestment.png"); */
  min-height: 186px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: contain;
  background-color: #F7F7F7;

}