
.referParentSec{
  /* background: linear-gradient(52.19deg, #FFF1EB 0%, #F7F5CA 98.95%); */
  background: #fff;
  min-height: 140px;
  display: flex;
  padding: 8px;
  box-shadow: 0px 2px 8px 0px rgba(106, 115, 129, 0.12);
  margin: 20px 0px;
  align-items: center;
  justify-content: center;
}
.referImgLeft{
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.referleftSec{
  width: 38%;
}
.referleftSecForImage{
  width: 60%;
  display: flex;
  flex-direction: column;
}

.referRightSec{
  width: 60%;
  padding:12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.referRightSecForImage {
  width: 38%;
padding:12px 16px;
display: flex;
justify-content: space-around;
}

.knowMore{
  padding: 6px;
}
.BoxDesignInfo{
  border: 1px solid rgb(146, 142, 142);
  border-radius: 6px;
  padding: 8px 4px;
  margin: 10px 4px;
}
.FaqParent{
  padding: 1rem 6px;
}
.AboutPage{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.BottomAbout{
  position:fixed;
  bottom:0;
  width:30%;
}
button.btn.designWelcomebtn {
  border: 1px solid #000;
  min-width: 80%;
  margin: auto;
}

.bootomSec {
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;

  
}
.btn-Accept{
  /* background: linear-gradient(93.84deg, #2D2F51 0%, #004E92 100%); */
  color: #fff !important;
  border-radius: 8px;
  width: 80%;
  display: inline-block;
  text-align: center;
  border: none;
  outline: none;
  font-size: calc(1rem + .9vw);
  padding: 0.4rem 2rem;
  margin: 1rem auto;
  min-height: 35px;
  position: relative;
}
.custumAgree{
  font-size: 0.8rem;
}
.btn-Accept_inner{
  background: linear-gradient(93.84deg, #2D2F51 0%, #004E92 100%);
  color: #fff !important;
  border-radius: 4px;
  border: none;
  display: block;
  outline: none;
  position: absolute;
  width: 100%;
  left: 0;
  height: 49px;
  top: 0;
}
.btn-Accept:disabled,
.btn-Accept[disabled]{
  border: 1px solid #999999;
  background: #cccccc;
  color: #2c2626;
}
.btn-Accept_inner:disabled, .btn-Accept_inner[disabled]  .form-check .btn-Accept{
  border: 1px solid #999999 !important;
  background: #cccccc !important;
  color: #272525;
}

/* know More pages css starts */

.BoxParentKnow{
 min-height: 206px;
 box-shadow: 0px 2px 8px rgba(106, 115, 129, 0.12);
 border-radius: 4px;
 margin: 1.5rem 0rem;

}
.HeaderKnow{
  background-color: #F7F7F7;
  min-height: 48px;
  display: flex;
  align-items: center;
  border-radius: 4px;


}
.HeaderKnow h6{
  font-size: 17px;
  padding: 11px 0px;
  margin-bottom: 12px !important;
  margin: 0;
  font-weight: 500;
  line-height: 26px;
}
.listStyle{
  color: #666666;
  font-size: 14px;
  margin: 0.6rem 0rem;
}
.listStyle ul {
  list-style: none;
  padding-right: 10px;
  padding-left: 20px;
}
li.liststyle{
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
}
.listStyle li::before {
    content: '•';
    font-size: 2rem;
    font-weight: bolder;
    padding-right: 5px;
    position: absolute;
    left: -4px;
    top: -10px;
}

/* Accordian Style css */

.parentAccordian:before{
  height: 0 !important;
}
/* 
Know more new page css starts */

.BoxParentKnowNew{
  min-height: 206px;
  /* box-shadow: 0px 2px 8px rgba(106, 115, 129, 0.12); */
  border-radius: 4px;
  margin: 1rem 0rem;
  padding: 4px;
 
}

.btnBottomDesignNew {
  background:rgb(45, 47, 81);
  border-radius: 5px;
  text-align: center;
  border: 0;
  outline: none;
  margin-bottom: 1rem;
  color: #FFF;
  padding: 4px 16px;
  font-size: 12px;
  width: 90%;
  font-weight: 500;
  font-family: "Gilroy-Semibold";
}

.secondAndThirdTile {

}


.NewHeaderKnow{
  /* background-color: #F7F7F7; */
  min-height: 48px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-family: "Gilroy-Regular";


}
.NewHeaderKnow h6{
  font-size: 17px;
  /* padding: 11px 16px; */
  margin: 0;
  font-weight: 500;
  line-height: 26px;
  font-family: "Gilroy-Regular";
}
.mineListStyle{
  position: relative;
  text-align: left;
  margin-bottom: 6px;
}

li.mineListStyle::marker {
  font-size: 26px;

}
.MuiAccordionSummary-content.Mui-expanded{
  margin: 0px;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px; 
  height: 20px;
  border: 2px solid #F48120; 
  border-radius: 50%; 
  margin-right: 8px;
  padding: 1px;
}

/* Style the checked radio button */
input[type="radio"]:checked {
  background-color: #F48120;
  border-color:#F48120;
} 
.form-check-input:focus{
  box-shadow:0 0 0 0.25rem #f481208c;
}