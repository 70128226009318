.titleAnimation{
    transform: translateX("30%");
    transition: all 300ms linear;
  
}

.reverseTitleAnimation{
    transform: translateX(0px);
    transition: all 300ms linear;
}

@media only screen and (max-width: 600px) {
 .titleAnimation{
    text-align: end;
    width: 100%;
    font-size: 3.5vw !important;
 }       
}