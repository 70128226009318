.Main-parent {
  max-width: 667px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  padding-top: 2rem;
  width: 100%;
 
}
.custum-img {
  width: 250px;
  height: 190px;
}
.headingStyle {
  color: #f48020;
  font-size: calc(1.7rem + .6vw);
  border-bottom: 4px solid #2c69b3;
  padding-bottom: 0.6rem;
}
.middleContent {
  font-size: calc(1.2rem + .6vw);
  text-align: center;
  color: #7b7b7b;
}

.slidesDesignNew {
  color: #000;
}
.btn-custum {
  font-size: calc(1.2rem + .6vw);
  margin-top: 3rem;
  text-align: center;
  background: #eb7f25;
  padding: 8px 20px;
  margin-bottom: 6rem;
  border-radius: 8px;
  width: 40%;
  border: 0px solid #eb7f25;
  color: #fff;
}

/* Slick slider css starts */
.slick-dots {
  bottom: 50px !important;
}
.slick-dots li button:before {
  font-size: 12px !important;
}

.SlideDesign {
  background-image: url(../Images/ic_login_bg.png);
  object-fit: cover;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.smallContent {
  color: #7b7b7b;
  font-size: calc(0.9rem + .6vw);
}

.left-sec-icon {
  font-size: 2rem;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.wraperIcon {
  color: #eb7f25;
  background-color: #a99f9f57;
  border-radius: 50%;
  font-size: 1.4rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Dashboard-Parent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.bootomSec {
  margin-top: auto;
  padding-bottom: 2rem;
  padding-left: 2rem;
  
}
.btn-Accept{
  background-color: #2c69b3;
  color: #fff !important;
  border-radius: 8px;
  width: 80%;
  display: inline-block;
  text-align: center;
  border: none;
  outline: none;
  font-size: calc(1.2rem + .9vw);
  padding: 0.4rem 2rem;
  margin: 1rem auto;
  min-height: 53px;
  position: relative;
}
.btn-Accept_inner{
  background-color: #2c69b3;
  color: #fff !important;
  border-radius: 8px;
  border: none;
  display: block;
  outline: none;
  position: absolute;
  width: 100%;
  left: 0;
  height: 49px;
  top: 0;
}
.btn-Accept:disabled,
.btn-Accept[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.btn-Accept_inner:disabled, .btn-Accept_inner[disabled]  .form-check .btn-Accept{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666;
}

.WelcomeParent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 5px;
}
.logoImg{
  /* max-width: 70%; */
  width: 174px;
  height:60px ;
  /* margin: 2rem auto; */
  margin-top: 5rem;
  display: flex;
  justify-content: center;

}

.SignUpText{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 16px;
}

.ResendOTPText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 5px;
}

input:disabled, Button:disabled{
  color: gray
}


.midImage {
  width: 100%;
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MidImageWelcome{
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;


}
.welcomeOuter{
  width: 80%;
  margin: auto;
}
.btnSec{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0px 16px;

}

.innerdesignbtn {
  color: #fff;
  background: linear-gradient(93.84deg, #2D2F51 0%, #004E92 100%);
  height: 100%;
  width: 100%;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  height: 40px;

}

.innerdesignbtnCustom {
color: #fff;
height: 44px;
width: 100%;
background-color: rgb(45, 47, 81);
border: 0px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
}

.bootomSecFooter{
  display: flex;
  color: #000;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}
.ankerDesign{
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
}

.crosssign {
  display: inline-grid;
  place-content: center;
  /* aspect-ratio: 1;
  min-inline-size: 1.25em; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d12021;
}

.crosssign::before {
  content: "\D7";
  color: #fff;
  font-weight: bold;
}


* {
  box-sizing: border-box;
}

.mobInput {
  border: 0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
}

.mobInput:focus {
  outline: none;
}


.editButton {
  background: transparent;
  border: 0;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.ptsitefootersubmit {
  position: relative;

}

.buttonDesign {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 44px;
  width: 50%;
  background-color: rgb(45, 47, 81) !important;
  border: 0px;
  color: rgb(255, 255, 255) !important;
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.buttonDesign:disabled {
  background-color: #cccccc !important; /* Gray color when disabled */
  cursor: not-allowed; /* Change cursor to not-allowed when disabled */
}
::-ms-reveal {
  display: none;
}