:root {
  --s-width: 40px;
  --s-gutter: 2.5rem;
  --c-background: rgb(0, 0, 0);
  --c-accent: hsl(213, 74%, 58%);
}

.stepper {
  /* Your stepper CSS styles */
  border: 2px solid #cccccc;
  border-radius: 3px;
  width: 44px;
}

.container-mine {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.container__item {
  flex: 0 0 auto;
  width: var(--s-width);
}

/* Additional CSS styles as needed */
