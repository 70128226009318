
/* .TabStyle, .TabStyle:hover {
    color: #F0F1FF;
  } */

.TabStyle:after{
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    margin-top: 50px;
    position: absolute;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;}


  .TabStyle {
    /* height: 2px;
    margin-top: 30px;
    width: 100%;
    background: #3ebd61; */
  }


/* .TabStyle  div:hover div{
    color: #ea7713 !important;
} */

.TabStyle  div:hover div span{
    /* color: #ea7713 !important; */
}


.tableThstyle{
  color: black !important;
  font-family:"Gilroy-SemiBold" !important;
  font-size: 12px !important;
}

.rightFilter{
  color:#333333;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.filterMain{
  width: 100%;
}

.tableHeading {
  font-size: 17px;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  color: #333333;
}

.cardHead {
  font-size: 24px;
  font-family: "Gilroy-Bold";
  font-weight: 700;
  color: #333333;
}

.inputStyle {
  width: 2.75rem !important;
  height: 2.75rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid #C2C2C2;
  font-size: 20px;
}

.DesignTab{
  background-color:#F7F7F7;
  box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2), inset 0px 2px 4px rgba(97, 97, 97, 0.2);
  border-radius: 8px;
}
.DesignTab .Mui-selected{
  background-color: #2D2F51 !important;
 }
 .tabPanelDesign div{
   padding: 6px 0px;
 }
 .chartparentpadding {
  padding: 0px 16px;
}

.infoHeading {
  font-family: "Gilroy-Medium";
  font-weight:500;
  font-size:21px;
  color:#666666;
}

.infoValue {
  font-family: "Gilroy-SemiBold";
  font-weight:600;
  font-size:28px;
  color:#333333;
  text-align: left;
}

@media only screen and (max-width: 380px) {
  .chartparentpadding {
    padding: 0px 6px;
  }
}

@media only screen and (max-width: 360px) {
  .chartparentpadding {
    padding: 0px 4px;
  }
}

@media only screen and (max-width: 340px) {
  .chartparentpadding {
    padding: 0px 2px;
  }
}