.Headermain{
    background-color: #ffffff;
    /* height: 96px; */
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 100%;
    max-width: 450px;
    box-shadow: -2px -6px 10px 6px   rgb(206, 206, 206);
    position: fixed;
    padding-top: 8px;
    top: 0;
    width: 100%;
    z-index: 1;
    flex-direction:column;

}

.Headermain::after {
    content:"";
    position:absolute;
    width:100%;
    bottom:1px;
    z-index:-1;
    transform:scale(.9);
}

.headerLeft{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between ;

}
.headerLeft .title{
    font-family: 'Gilroy-SemiBold';
    font-size: 17px;
    color: #141619;
}
.headerLeft .backIcon{
    margin-top: 7.5px;
}

.headerLeft .titleStyle{
    width: 90%;
    height: 100%;
    /* border: 1px solid green; */
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    /* justify-content:flex-end; */
    justify-content: space-evenly;
    align-items:flex-start;
}
.headerLeft p{
    margin: 0;
   }
.headerRight{
margin-right: 10px;

}

.headerRight img{
    width: 100%;
    height: 50%;
}

.tabHead{
    height: 40%;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.tabStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
}
.Headermain .tabinnerStyle{
   width: 36px;
   height: 4px;
   background-color: rgba(244, 129, 32, 0.5);
   border-radius: 10px;
}

.Headermain .tabSucessStyle{
    width: 36px;
    height: 4px;
    background-color:  rgba(62, 189, 97, 0.75);
    border-radius: 10px;  
}
.Headermain .disableStyle{
    width: 36px;
    height: 4px;
    background-color: grey;
    border-radius: 10px;
}




/* AmountPaybale style */


.AmountPayableMain{
    /* margin-top: 2px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffb133;
    color: white;
    height: 52px;
    width: 100%;    
}

.AmountPayableMain .AmountPayableInner{
    margin-top: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: '#141619';
    /* background-color: rgba(47, 115, 218, 0.05); */
}
/*  Input text filed style */


.inputMain{
    height: 100px;
    margin-top: 20px;
    position: relative !important;
    border: '1px solid green';
}
.inputMain .textField{
    border: none !important;
    outline: none;
    border: '1px solid green';

}
.inputMain .InfoIconStyle{
    position: absolute;
    bottom:-12px ;

}

/* //OTPComponentMain */

.OTPComponentMain{
    /* background-color: red; */
    width: 100%;
    min-height: 100px;
}
.OTPComponentMain .focusStyle{
    border: 2px solid #f48120 !important;


}

.OTPComponentMain .OTPContainerInput{
    width: 60%;
    /* min-width: 200px; */
    display: flex ;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 500px;
    /* border: 1px solid red; */
}

.OTPComponentMain .OTPInput{
    border: 1.5px solid #f48120;
    outline: none;
    width: 48px !important;
    height: 48px !important;
    border-radius: 5px;
    margin-right: 5px;
    background-color: white;

}

@media(max-width:320px){
    .OTPComponentMain .OTPInput{
        width: 38px !important;
         height: 38px !important;
    }
}

.OTPComponentMain .OTPInputError{
    border: 2px solid red;
    width: 48px !important;
    height: 48px !important;
    border-radius: 5px;
    background-color: #fef9f4;
}


/* CreditLineCard style */

.CreditLineCardMain{
    height: 182px;
    background-image: linear-gradient(-30deg,#004e92, #2d2f51);
    margin: 25px 0px;
    border-radius: 8px;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.RetailCreditLineCardMain{
    min-height: 120px;
    background-image: linear-gradient(-30deg,#004e92, #2d2f51);
    margin: 25px 0px;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 6px;
    /* margin-top: 5px; */

}

.CreditLineCardMain p{
    margin-bottom: 2px !important;
}
.CreditLineCardMain img{
    overflow: hidden;
    width: 137px;
    height: 42px;
    border-radius: 10px;
}
.CreditLineCardMain .CreditLineText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    
}

.CreditLineCardMain .CreditLineAmountText{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 
}


.CreditLineCardMain .borderLine{
    border-bottom: 1px solid white;
    width: 100%;
    opacity: 0.4;
}
/*Default Bar Style */

.DefaultBarMain{
    background-color:#dfe6f0;
}


/* drop down*/

.DropdownMain{
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(243, 243, 243);
    height: 58px;
    font-family: 'Gilroy-Medium';


}

.DropdownMain .innerLabelText{
        width: 100%;
}



.shadowbox {
    background-color: #3D6AA2;
    width: 160px;
    height: 90px;
    position: absolute;
    top: calc(10% - 10px);
    left: calc(50% - 80px);
}

.shadowboxbottom:after {
    content:"";
    position:absolute;
    width:100%;
    bottom:1px;
    z-index:-1;
    transform:scale(.9);
    box-shadow: 0px 0px 8px 2px #000000;
}

.textField{
    color: #000000 !important;

}
.countryCodeText{
    color: #000000 !important;
    /* font-weight: 500; */

}
/* .countryCodeText, .textField {
    position: relative;
    display: block;
    width: 174px;
    box-sizing: border-box;
}

.countryCodeText::after {
    content: attr(data-domain);
    position: absolute;
    top: 46px;
    left: 12px;
    display: block;
    font-size: 15px;    

} */

.inputboxMain{
    /* position: relative; */
    border: 1px solid transparent;
    height: 50px;
}



@font-face{
    font-family: Gilroy-SemiBold;
    src: url(../fonts/gilroy/Gilroy-SemiBold.ttf);
}


@font-face{
    font-family: Gilroy-Regular;
    src: url(../fonts/gilroy/Gilroy-Regular.ttf);
}

@font-face{
    font-family: Gilroy-Medium;
    src: url(../fonts/gilroy/Gilroy-Medium.ttf);
}